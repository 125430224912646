import React from "react"
import styled from "styled-components"
import theme from "../../theme"

const DisclamierPage: React.FC = () => {
  return (
    <Container>
      <h1>Disclaimer</h1>
      <br />
      <p>
        Welcome to our website. If you continue to browse and use this website,
        you are agreeing to comply with and be bound by the following terms and
        conditions of use, which together with our privacy policy govern
        Workerslife’s relationship with you in relation to this website. If you
        disagree with any part of these terms and conditions, please do not use
        our website.
      </p>
      <p>
        The terms Workerslife or “us” or ‘we” refers to the owner of the website
        whose registered office is PGC House, 273 Paul Kruger Street, Pretoria,
        0001. The term “you” refers to the user or viewer of our website.
      </p>
      <p>
        The use of the Workerslife website and the related products and/ or
        services shall be governed by and construed in accordance with the laws
        of the Republic of South Africa. Should you apply for any products or
        services on this website, you consent and submit to the jurisdiction of
        the courts of the Republic of South Africa in regards to all
        proceedings, actions, applications instituted by either party against
        the other and in any way arising from any of the stated terms and
        conditions. The information contained on this website is to provide you
        with information about Workerslife, our products and services. It is
        subject to change without notice. The information provided on this
        website should not be treated as investment or professional advice.
      </p>
      <p>
        We do not provide any warranty or guarantee, expressed or implied, as to
        the accuracy, availability, completeness and suitability of the
        information and products found or offered on this website for any
        particular purpose. You acknowledge that such information and products
        may contain inaccuracies or errors and we expressly exclude liability
        for any such inaccuracies or errors to the fullest extent permitted by
        law. While we have taken care to ensure that the content of this website
        is accurate and up to date, this website and the services accessible on
        this website are provided “as is”. Your use of any information, products
        or services on this website is entirely at your own risk, for which we
        shall not be liable. It shall be your own responsibility to ensure that
        any products, services or information available through this website
        meet your specific requirements.
      </p>
      <p>
        Workerslife reserves the right in our sole discretion to amend this
        website, the products, the information described on this website and the
        terms and conditions from time to time and you agree that you will
        review the terms whenever you visit our website for any such amendments.
        This website contains material which is owned by or licensed to us. This
        material includes, but is not limited to, the design, layout, look,
        logos, and graphics. You are authorised to view and download, print and
        make copies of such printouts provided that you use the material for
        personal and non – commercial purposes only. You may copy the content to
        individual third parties for their personal use, but only if you
        acknowledge this website as the source of the information and /or
        materials. You may not without Workerslife’s express
      </p>
      <p>
        Workerslife makes no warranty as to the operation, availability or
        functionality of this website and we further make no warranty that the
        information and products available on this website are free of viruses,
        destructive materials or any other data which may corrupt or compromise
        the operation or content of your computer system, computer network,
        hardware or software. In no event will we be liable for any loss or
        damage including without limitation, indirect or consequential loss or
        damage, or any loss or damage whatsoever arising from loss of data or
        profits arising out of, or in connection with, the use of this website.
        Every effort is made to keep this website up and running smoothly.
        However, Workerslife takes no responsibility for, and will not be liable
        for the website being temporarily unavailable due to technical issues
        beyond our control. From time to time this website may also include
        links to other websites. These links are provided for your convenience
        to provide further information. They do not signify that we endorse the
        website(s). We have no responsibility for the content of the linked
        website(s). Unauthorised use of this website may give rise to a claim
        for damages and/or criminal offence
      </p>
    </Container>
  )
}

export default DisclamierPage

const Container = styled.div`
  padding: 0 96px 99px 58px;
  h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 800;
    font-size: 86px;
    line-height: 115px;
    text-align: center;
    padding-bottom: 1rem;
  }
  p {
    font-family: "SofiaPro";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 0px;
    p {
      padding: 0 58px 38px 58px;
    }
    h1 {
      font-size: 66px;
    }
  }
`
