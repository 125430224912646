import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import DisclaimerPage from "../sections/disclaimer.tsx/DisclaimerPage"

const Disclaimer: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Disclaimer"
        description="Read our disclaimer to understand the terms and limitations of using Workerslife's financial services. Ensure clarity before proceeding with any transactions."
      />
      <DisclaimerPage />
    </PageWrapper>
  )
}

export default Disclaimer

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
